




















































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  deleteCall,
  deleteCallBulk,
  exportCalls,
  getCalls,
  getCallsCount
} from '@/api/calls'
import {
  clientsFilter,
  createFilter,
  dateFilter,
  getSortItems, ignoreCaseSearchField,
  prepareListFilters,
  propertiesFilter, searchFieldConcat, searchFieldConcatReverse,
  usersFilter
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'CallList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected: any = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private hasPermission = hasPermission

  private filters: any = [
    clientsFilter(),
    propertiesFilter(),
    dateFilter({
      key: 'call_date',
      label: 'table.callDate'
    })
  ]

  created() {
    this.getQueryParams()
    this.getList()
    if (hasPermission(['LIST_OTHERS_CALLS'])) {
      this.filters.push(usersFilter())
    }
  }

  private getQueryParams() {
    const query: any = this.$route.query || {}
    this.filters.push(dateFilter({
      from: query.from ? new Date(Number(query.from)) : null,
      to: query.to ? new Date(Number(query.to)) : null
    }))
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteCall({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private getPhones(client: any) {
    const phones = []
    if (client.secondary_phone) {
      phones.push(client.secondary_phone)
    }
    if (client.main_phone) {
      phones.push(client.main_phone)
    }
    return phones.join(' - ')
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteCallBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: [
        searchFieldConcat('user.'),
        searchFieldConcatReverse('user.'),
        searchFieldConcat('client.'),
        searchFieldConcatReverse('client.'),
        ignoreCaseSearchField('client.main_phone'),
        ignoreCaseSearchField('client.secondary_phone'),
        ignoreCaseSearchField('property.full_code')
      ],
      filters: filters
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getCallsCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/call/edit/' + id)
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportCalls(this.selected)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getCalls(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
