var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",staticStyle:{"background-color":"#efeff1"}},[_c('list-toolbar',{attrs:{"with-pagination":true,"list-query":_vm.listQuery,"total":_vm.total,"has-selected":_vm.selected.length,"title":_vm.$t('callList.title')},on:{"value-changed":_vm.onSearchChanged,"pagination":_vm.getList}},[_c('template',{slot:"actions"},[_c('el-button',{attrs:{"icon":"el-icon-s-operation"},on:{"click":function($event){return _vm.handleCommand('filters')}}},[_vm._v(" "+_vm._s(_vm.$t('actions.filters'))+" ")])],1),_c('template',{slot:"select-actions"},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-download"},on:{"click":function($event){return _vm.handleCommand('download')}}},[_vm._v(" "+_vm._s(_vm.$t('actions.download'))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_CALLS']),expression:"['DELETE_CALLS']"}],attrs:{"size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleCommand('delete')}}},[_vm._v(" "+_vm._s(_vm.$t('actions.delete'))+" ")])],1)],2),_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":""},on:{"sort-change":_vm.handleSortChange,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"width":"45","type":"selection"}}),_c('el-table-column',{attrs:{"align":"center","prop":"call_date","label":_vm.$t('table.callDate'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.call_date, '{y}-{m}-{d} {h}:{i}')))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"client.translations.first_name,client.translations.last_name","sortable":"custom","label":_vm.$t('table.client')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.client)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.clientPhone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getPhones(row.client)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"property.full_code","sortable":"custom","label":_vm.$t('table.property')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.property, ['full_code'])))])]}}])}),(_vm.hasPermission(['LIST_OTHERS_CALLS']))?_c('el-table-column',{attrs:{"align":"center","prop":"user.translations.first_name,user.translations.last_name","sortable":"custom","label":_vm.$t('table.collaborator')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.user)))])]}}],null,false,1957026714)}):_vm._e(),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.notes')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.notes))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.dateCreated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_created, '{y}-{m}-{d} {h}:{i}')))])]}}])}),(_vm.hasPermission(['EDIT_CALLS', 'READ_CALLS', 'DELETE_CALLS']))?_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.actions'),"width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['EDIT_CALLS', 'READ_CALLS']),expression:"['EDIT_CALLS', 'READ_CALLS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.redirectToEdit(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.edit'))+" ")])],1),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_CALLS']),expression:"['DELETE_CALLS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.deleteItem(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.delete'))+" ")])],1)]}}],null,false,4013255488)}):_vm._e()],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1),_c('FiltersDrawer',{attrs:{"filters":_vm.filters,"count":_vm.count,"show":_vm.showFilters,"size":30,"cols-per-row":1},on:{"filters-changed":_vm.filtersChanged,"submit-filters":_vm.submitFilters,"close":function($event){_vm.showFilters = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }